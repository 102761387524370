<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('paymentMethod.configPaymentMethod', {method: $t(getPaymentMethodName(paymentMethod.type))})"
          color="green"
          flat
          full-width
        >
          <v-form
            ref="form">
            <v-container py-0>
              <v-layout
                wrap
                class="justify-center align-center">
                <v-flex
                  xs3
                  sm3
                  m9>
                  <span>{{ $t('paymentMethod.turnOn') }} / {{ $t('paymentMethod.turnOff') }}</span>
                </v-flex>
                <v-flex
                  xs9
                  sm3
                  md9>
                  <v-switch
                    v-model="paymentMethod.enable"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                class="justify-center align-center">
                <v-flex
                  xs3
                  sm3
                  m9>
                  <span>{{ $t('evoucher.issuance.description') }}</span>
                </v-flex>
                <v-flex
                  xs9
                  sm3
                  md9>
                  <v-textarea
                    v-model="paymentMethod.description"
                    type="text"
                    columns="3"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                v-if="paymentMethod.type == paymentMethodType.BANK_TRANSFER"
                wrap
                class="justify-center align-center">
                <v-flex
                  xs3
                  sm3
                  m9>
                  <span>{{ $t('paymentMethod.accountInfo') }}</span>
                </v-flex>
                <v-flex
                  xs9
                  sm3
                  md9>
                  <v-data-table
                    :no-data-text="$t('common.noDataAvailable')"
                    :headers="paymentMethodHeaders"
                    :items="bankInfoList"
                    :single-select="true"
                    hide-actions
                    style="white-space: nowrap;"
                  >
                    <template
                      slot="headerCell"
                      slot-scope="{ header }"
                    >
                      <span
                        class="text--darken-3 font-medium"
                        v-text="$t(header.text)"
                      />
                    </template>
                    <template
                      slot="items"
                      slot-scope="{ item }"
                    >
                      <td>{{ item.stt }}</td>
                      <td>{{ item.nameAccount }}</td>
                      <td>
                        {{ item.numberAccount }}
                      </td>
                      <td>{{ item.bankName }}</td>
                      <td>{{ item.branchName }}</td>
                      <td>
                        <v-chip
                          :color="getColor(item.enabled)"
                          dark>
                          {{ $t(getStatus(item.enabled)) }}
                        </v-chip>
                      </td>
                      <td class="text-xs-center">
                        <!-- Delete info -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              fab
                              small
                              color="primary"
                              style="color: #fff;"
                              v-on="on"
                              @click="onUpdateTransferAccount(item)"
                            >
                              <v-icon dark>mdi-square-edit-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("Cập nhật") }}</span>
                        </v-tooltip>
                        <!-- Delete info -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              fab
                              small
                              color="red"
                              style="color: #fff;"
                              v-on="on"
                              @click="onConfirmDeleteBankInfo(item)"
                            >
                              <v-icon dark>mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("paymentMethod.deleteAccount") }}</span>
                        </v-tooltip>
                      </td>
                    </template>
                  </v-data-table>
                  <v-btn
                    style="margin-right: 0;"
                    class="pull-left"
                    color="success"
                    @click="addTransferAccount"
                  >
                    <v-icon
                      class="mr-2"
                      dark>mdi-plus</v-icon>
                    <span style="text-transform: none;">{{
                      $t('paymentMethod.addNewAccount')
                    }}</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout
                wrap>
                <v-btn
                  style="margin-right: 0;"
                  class="pull-left"
                  color="warning"
                  @click="onBack"
                >
                  <v-icon
                    class="mr-2"
                    dark>mdi-keyboard-backspace</v-icon>
                  <span style="text-transform: none;">{{
                    $t('common.back')
                  }}</span>
                </v-btn>
                <v-btn
                  style="margin-right: 0;"
                  class="pull-left"
                  color="success"
                  @click="onSaveTransferInfo"
                >
                  <v-icon
                    class="mr-2"
                    dark>mdi-lead-pencil</v-icon>
                  <span style="text-transform: none;">{{
                    $t('common.save')
                  }}</span>
                </v-btn>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <addTransferInfoModal
      ref="addTransferInfoModal"
      @onConfirm="onAddUpdateBankInfo" />
    <confirm-modal
      ref="confirmDeleteBankModal"
      :title="confirmDeleteBankInfoTitle"
      @onConfirm="onDeleteBankInfo"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddTransferInfoModal from './AddTransferInfoModal'
import ConfirmModal from 'Components/ConfirmModal'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import PaymentMethodType from 'enum/paymentMethodType'
export default {
  components: {
    AddTransferInfoModal,
    ConfirmModal
  },
  data: () => ({
    isLoading: false,
    valid: false,
    deletingBankId: null,
    confirmDeleteBankInfoTitle: '',
    paymentMethodHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'paymentMethod.transferInfoDetail.nameAccount',
        value: 'nameAccount'
      },
      {
        sortable: false,
        text: 'paymentMethod.transferInfoDetail.numberAccount',
        value: 'numberAccount'
      },
      {
        sortable: false,
        text: 'paymentMethod.transferInfoDetail.nameBank',
        value: 'bankName',
        align: 'center'
      },
      {
        sortable: false,
        text: 'paymentMethod.transferInfoDetail.branchBank',
        value: 'branchName',
        align: 'center'
      },
      {
        sortable: false,
        text: 'Trạng thái',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    bankInfoList: [],
    paymentMethod: {
      description: null,
      enable: false,
      type: null
    },
    paymentMethodType: {
      CASH_ON_DELIVERY: PaymentMethodType.CASH_ON_DELIVERY,
      BANK_TRANSFER: PaymentMethodType.BANK_TRANSFER
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
  },
  created () {
    let paymentMethod = this.$route.params.id
    let paymentId = sessionStorage.getItem('paymentId')
    let filter = {
      params: {
        paymentMethod: paymentMethod,
        paymentInfoId: paymentId !== 'null' && !functionUtils.isNull(paymentId) ? paymentId : 0
      }
    }
    this.GET_PAYMENT_METHOD_DETAIL(filter).then(
      function (res) {
        let data = res.data
        this.paymentMethod.type = data.payment_method
        this.paymentMethod.description = data.description
        this.paymentMethod.enable = data.enabled
      }.bind(this)
    )
    this.getBankInfoList()
  },
  methods: {
    /**
     * Get color
     */
    getColor (enabled) {
      if (!enabled) return 'red'
      else return 'green'
    },
    /**
     * Get status
     */
    getStatus: function (enabled) {
      if (enabled) {
        return 'evoucher.state.active'
      }
      return 'evoucher.state.inactive'
    },
    /**
     * Back
     */
    onBack: function () {
      window.history.back()
    },
    /**
     * Save transfer info
     */
    onSaveTransferInfo: function () {
      let paymentMethod = this.$route.params.id
      let paymentId = sessionStorage.getItem('paymentId')
      let filter = {
        paymentMethod: paymentMethod,
        paymentInfoId: paymentId !== 'null' && !functionUtils.isNull(paymentId) ? paymentId : 0,
        description: this.paymentMethod.description,
        enable: this.paymentMethod.enable
      }
      this.CREATE_UPDATE_PAYMENT_INFO(filter).then(
        function (res) {
          let data = res.data
          sessionStorage.setItem('paymentId', data.paymentId)
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    getBankInfoList: function () {
      this.GET_BANK_TRANSFER_LIST().then(
        function (res) {
          let data = res.data
          this.bankInfoList = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              stt: i + 1,
              id: data[i].id,
              nameAccount: data[i].name_account,
              numberAccount: data[i].number_account,
              bankName: data[i].name_bank,
              branchName: data[i].branch_bank,
              enabled: data[i].enabled,
              lowApproximate: data[i].low_approximate,
              highApproximate: data[i].high_approximate
            }
            this.bankInfoList.push(obj)
          }
        }.bind(this)
      )
    },
    onConfirmDeleteBankInfo: function (item) {
      this.confirmDeleteBankInfoTitle = this.$t('payment.confirmDeleteBankInfo', { name: item.nameAccount })
      this.deletingBankId = item.id
      this.$refs.confirmDeleteBankModal.onShowModal()
    },
    onDeleteBankInfo: function () {
      this.DELETE_BANK_INFO({ id: this.deletingBankId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getBankInfoList()
        }.bind(this)
      )
    },
    onAddUpdateBankInfo: function (item) {
      let filter = {
        bankId: item.id ? item.id : 0,
        nameAccount: item.nameAccount ? item.nameAccount : null,
        numberAccount: item.numberAccount ? item.numberAccount : null,
        bankName: item.bankName ? item.bankName : null,
        branchName: item.branchName ? item.branchName : null,
        enabled: item.enabled ? item.enabled : false,
        lowApproximate: item ? item.lowApproximate : null,
        highApproximate: item ? item.highApproximate : null
      }
      this.CREATE_UPDATE_BANK_INFO(filter).then(
        function (res) {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getBankInfoList()
        }.bind(this)
      )
    },
    /**
     * Update transfer account
     */
    onUpdateTransferAccount: function (item) {
      this.$refs.addTransferInfoModal.onShowModal(2, item)
    },
    addTransferAccount: function () {
      this.$refs.addTransferInfoModal.onShowModal(1)
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_PAYMENT_METHOD_DETAIL',
      'GET_BANK_TRANSFER_LIST',
      'CREATE_UPDATE_PAYMENT_INFO',
      'CREATE_UPDATE_BANK_INFO',
      'DELETE_BANK_INFO'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
