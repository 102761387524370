<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'1000px'"
    persistent>
    <v-card>
      <material-card
        :title="$t('paymentMethod.bankInfo.title')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="editedItem.nameAccount"
                  :label="$t('paymentMethod.bankInfo.accountName')"
                  :name="$t('paymentMethod.bankInfo.accountName')"
                  :rules="[ruleRequiredValue]"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="editedItem.numberAccount"
                  :label="$t('paymentMethod.bankInfo.accountNumber')"
                  :name="$t('paymentMethod.bankInfo.accountNumber')"
                  :rules="[ruleRequiredValue]"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="editedItem.bankName"
                  :label="$t('paymentMethod.bankInfo.bankName')"
                  :name="$t('paymentMethod.bankInfo.bankName')"
                  :rules="[ruleRequiredValue]"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="editedItem.branchName"
                  :label="$t('paymentMethod.bankInfo.bankBranch')"
                  :name="$t('paymentMethod.bankInfo.bankBranch')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="lowApproximateFormat"
                  :label="$t('paymentMethod.bankInfo.lowApproximate')"
                  :name="$t('paymentMethod.bankInfo.lowApproximate')"
                  :rules="[ruleMustGreaterThanZero]"
                  type="text"
                  @input="formatApproximateValue('lowApproximate', lowApproximateFormat)"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="highApproximateFormat"
                  :label="$t('paymentMethod.bankInfo.highApproximate')"
                  :name="$t('paymentMethod.bankInfo.highApproximate')"
                  :rules="[ruleMustGreaterThanZero]"
                  type="text"
                  @input="formatApproximateValue('highApproximate', highApproximateFormat)"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-checkbox
                  v-model="editedItem.enabled"
                  :label="$t('paymentMethod.turnOn')"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onClose"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    isShowModal: false,
    // nameAccount: null,
    // numberAccount: null,
    // bankName: null,
    // branchName: null,
    actionTypeSelected: null,
    actionType: {
      ADD: 1,
      UPDATE: 2
    },
    editedItem: {
      nameAccount: null,
      numberAccount: null,
      bankName: null,
      branchName: null,
      enabled: true,
      lowApproximate: null,
      highApproximate: null
    },
    editedIndex: null,
    lowApproximateFormat: null,
    highApproximateFormat: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Greater than zero
     */
    ruleMustGreaterThanZero (value) {
      if (functionUtils.isEmptyString(value)) {
        return true
      }
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    isEmptyString (value) {
      return functionUtils.isEmptyString(value)
    },
    /**
     * Close
     */
    onClose: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        this.$emit('onConfirm', this.editedItem)
        this.isShowModal = false
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (type, item) {
      this.editedItem = {
        id: item ? item.id : null,
        nameAccount: item ? item.nameAccount : null,
        numberAccount: item ? item.numberAccount : null,
        bankName: item ? item.bankName : null,
        branchName: item ? item.branchName : null,
        enabled: item ? item.enabled : null,
        lowApproximate: item ? item.lowApproximate : null,
        highApproximate: item ? item.highApproximate : null
      }
      this.actionTypeSelected = type
      this.lowApproximateFormat = this.formatMoney(item ? item.lowApproximate : null)
      this.highApproximateFormat = this.formatMoney(item ? item.highApproximate : null)
      this.isShowModal = true
    },
    /**
     * Format min value
     */
    formatApproximateValue: function (valueFormatName, value) {
      if (!functionUtils.isEmptyString(value)) {
        let valueFormat = functionUtils.formatInteger(value && value.toString())
        if (valueFormatName === 'lowApproximate') {
          this.lowApproximateFormat = functionUtils.convertFormatNumberToMoney(valueFormat)
          this.editedItem.lowApproximate = valueFormat
        }
        if (valueFormatName === 'highApproximate') {
          this.highApproximateFormat = functionUtils.convertFormatNumberToMoney(valueFormat)
          this.editedItem.highApproximate = valueFormat
        }
      } else {
        if (valueFormatName === 'lowApproximate') {
          this.lowApproximateFormat = null
          this.editedItem.lowApproximate = null
        }
        if (valueFormatName === 'highApproximate') {
          this.highApproximateFormat = null
          this.editedItem.highApproximate = null
        }
      }
    },
    formatMoney: function (price) {
      return !functionUtils.isEmptyString(price) ? functionUtils.convertFormatNumberToMoney(price.toString()) : price
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
